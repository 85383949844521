@font-face {
  font-family: GrapeFont;
  src: url(./font/Gilroy-Light.ttf);
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: GrapeFont;
  src: url(./font/Gilroy-ExtraBold.ttf);
  font-weight:bold;
  font-style:normal;
}


body {
  margin: 0;
  font-size-adjust: none;
  font-family: GrapeFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background: #4e8ebf;  /* fallback for old browsers */
  background: radial-gradient(88.33% 113.42% at 71.34% 83.89%, #4ea10a 0%,  #879a09 32.29%,  #713585 58.85%,  #d1167a 85.5%,  #f395bb 98.96%);
  height:100%;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.profileAvatarIcon:hover {
  color:#fff!important;
}

.profile-background{
  background: linear-gradient(to bottom, #36325d 0%, #251A3A 48.6%, #251A3A 90.62%, #251A3A 10%);
  border-radius: 1px;
  min-height: 225px;
  min-width:250px;
  overflow:hidden;
  padding-bottom:2em;
  position:relative;
}

.art-header{
  background: none;
  backdrop-filter: blur(12.5px);
}

.grape-art-profile-img{
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: auto;
}

.grape-art-generic-placeholder-container{
  padding: 1em; 
  margin-bottom: 3em;
  width: 100%;
  background: #13151C;
  border-radius: 1px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.grape-art-feed-outer-container{
  position: relative;
  margin:0;
  padding:0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.grape-art-feed-inner-container{
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

.grape-art-feed-inner-img{
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.grape-art-feed-overlay{
  position:relative;
}

.grape-art-generic-dark-box{
  background: #13151C;
  min-width: 100%;
  min-height: 100%;
  padding: 1em;
  margin-top: 1em;
  border-radius: 1px; 
  z-index: 9999;
}

@keyframes avataranimation {
  0%   {color: white; }
  25%  {color: rgb(144, 202, 249); }
  50%  {color: white; }
  75%  {color: rgb(144, 202, 249); }
  100% {color: white; }
}

.buyNowButton {
  position: relative!important;
  background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);
  border-radius: 10px!important;
  color: #fff!important;
  animation: 0.5s ease-in;
}

.buyNowButton:hover {
  animation-name: avataranimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.wallet-adapter-button-trigger {
  line-height: 20px !important;
  background:none!important;
  border:1px solid #fff!important;
  border-radius: 24px!important;
}

@keyframes avataranimation {
  0%   {background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);}
  10%  {background: linear-gradient(306deg, #00F0DD 0%, #DC1FFF 100%);}
  20%  {background: linear-gradient(342deg, #00F0DD 0%, #DC1FFF 100%);}
  30%  {background: linear-gradient(18deg, #00F0DD 0%, #DC1FFF 100%);}
  40%  {background: linear-gradient(54deg, #00F0DD 0%, #DC1FFF 100%);}
  50%  {background: linear-gradient(90deg, #00F0DD 0%, #DC1FFF 100%);}
  60%  {background: linear-gradient(126deg, #00F0DD 0%, #DC1FFF 100%);}
  70%  {background: linear-gradient(162deg, #00F0DD 0%, #DC1FFF 100%);}
  80% {background: linear-gradient(192deg, #00F0DD 0%, #DC1FFF 100%);}
  90% {background: linear-gradient(234deg, #00F0DD 0%, #DC1FFF 100%);}
  100% {background: linear-gradient(270deg, #00F0DD 0%, #DC1FFF 100%);}
}

@media screen and (max-width: 550px) {
  .likeTab{
    display:none;
  }
  /* Overriding MUI Stylings for mobile screens */
  .wallet-adapter-button {
    line-height: 20px !important;
  }
  .wallet-adapter-button-start-icon{
    margin-right:0!important;
  }
  .wallet-adapter-solana-domain{
    display:none;
  }
  
  .header-logo {
    width: 40px !important;
  }
}